/**
 *
 * Build Tools for Building DOM Elements
 *
 */

utNavBuild = {};

/**
 *
 * Helper method to do a replace all in a string using the provided regex
 *
 * @param {regex} regex The pattern to look for to replace
 * @param {String} replaceWith The string to put in the place of matching items
 * @param {String} str The string the regex pattern will search in
 * @returns {unresolved}
 */

utNavBuild._replaceAll = function(regex, replaceWith, str) {
	return str.replace(new RegExp(regex, 'g'), replaceWith);
};

/**
 *
 * Builds a element node with no attributes and some display text
 *
 * @param {String} elementType
 * @param {String} displayValue
 * @returns {Element}
 */
utNavBuild.elementWithText = function (elementType, displayValue) {
	var element = document.createElement(elementType);
	element.appendChild(document.createTextNode(displayValue));
	return element;
};

/**
 *
 * Generic function that will build an element with optional displayText
 * and add attributes to the element based on the map using the map.key
 * as the attribute name and the map.value as the attributes value
 *
 * @param {string} elementToMake
 * @param {Object} elementAttributes
 * @param {string} displayText
 * @returns {Element}
 * example: utNavBuild.elementWithAttributes('div', {class: "foo", id: "faa"}, 'bar');
 */
utNavBuild.elementWithAttributes = function(elementToMake, elementAttributes, displayText) {
	var element = document.createElement(elementToMake);

	for (var prop in elementAttributes) {
		element.setAttribute(prop, elementAttributes[prop]);
	}

	if (displayText || displayText === 0) {
		element.appendChild(document.createTextNode(displayText));
	}
	return element;
};

/**
 *
 * Helps build a link will preventing the text javascript being in the
 * url.  One additional step against XSS.
 *
 * @param {String} link
 * @param {String} displayText Optional text to use for display. If not provided the link will be used as the display text value.
 * @returns {Element}]
 */
utNavBuild.anchor = function (link, displayText, wrapper) {
	link = utNavBuild._replaceAll('javascript', '', link);
	if (!displayText && !wrapper) {
		displayText = link;
	}
	return utNavBuild.elementWithAttributes('a', {href: utNavBuild._replaceAll('javascript', '', link)}, displayText);
};

/**
 *
 *
 * @param {svgPaths} array of path objects
 * @param {svgWidth} width
 * @param {svgHeight} height
 * @returns {svg element}
 */

utNavBuild.createSvg = function(svgPaths, svgWidth, svgHeight, svgClass) {
	
	if (!svgWidth) svgWidth = 48;
	if (!svgHeight) svgHeight = 48;
	svgViewBox = '0 0 ' + svgWidth + ' ' + svgHeight;
	svgWidth = svgWidth + 'px';
	svgHeight = svgHeight + 'px';

	var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.setAttribute('viewBox', svgViewBox);
		svg.setAttribute('x', '0px');
		svg.setAttribute('y', '0px');
		svg.setAttribute('width', svgWidth);
		svg.setAttribute('height', svgHeight);
		svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
		if (svgClass) svg.setAttribute('class', svgClass);

	if (svgPaths) {
		for (var i = 0; i < svgPaths.length; i++) {
			var path = document.createElementNS('http://www.w3.org/2000/svg' ,'path');
				path.setAttributeNS(null , 'd', svgPaths[i].d);
				path.setAttributeNS(null , 'fill', svgPaths[i].fill);
			svg.appendChild(path);
		}
		return svg;
	}
};

/**
 *
 * ready fires callback when the document readyState is 'complete'
 * 
 * @callback {function} callback
 *
 * Reference:
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */

utNavBuild.ready = function(callback) {
	document.addEventListener('readystatechange', function(event){
		if (event.target.readyState === 'complete') {
			callback();
		}
	});
};
/**
 * FOOTER INIT
 */

var utFooter = {};

// Check if header js already handled these vars
if (typeof utHeader != 'undefined') {
	utFooter.url = utHeader.url;
	utFooter.head = utHeader.head;
	utFooter.body = utHeader.body;
}
else {
	//Setup URL for Local/test/stage/www.prod/secure.prod
	utFooter.url = function() {
		switch (window.location.host) {
			case 'localhost':
			case 'localhost:8080':
			case 'localhost.utah.gov':
			case 'prototype.utah.gov':
			case 'secure.office.uii':
			case 'stage.utah.gov':
			case 'secure.utah.gov':
			case 'www.utah.gov':
				return '/nav/';
				break;
			default:
				console.log('https://www.utah.gov/nav/ is css source');
				return 'https://www.utah.gov/nav/';
		}
	};

	// Get Head Element
	utFooter.head = document.getElementsByTagName('head')[0];
	// Get Body Element
	utFooter.body = document.getElementsByTagName('body')[0];
	// Add link to header <link rel="stylesheet" href="nav.css">
	utFooter.cssUrl = utFooter.url() + 'nav.css';
	utFooter.csslink = utNavBuild.elementWithAttributes('link', {
			rel: 'stylesheet',
			href: utFooter.cssUrl
		}, null);
	utFooter.head.appendChild(utFooter.csslink);
} // if (utHeader)

// Get JS Element
utFooter.possible = 'script[src*="navfooter.js"], script[src*="fluidfooter.js"], script[src*="650footer.js"], script[src*="footer.js"]';
utFooter.footer = document.querySelectorAll(utFooter.possible)[0];
utFooter.source = utFooter.footer.src;

// Set Class for header src type
if (utFooter.source.indexOf('navfooter.js') != -1 || utFooter.source.indexOf('fluidfooter.js') != -1) {
	utFooter.class = 'UTfluid'
}
else if (utFooter.source.indexOf('650footer.js') != -1) {
	utFooter.class = 'UT650'
}
else if (utFooter.source.indexOf('footer.js') != -1) {
	utFooter.class = 'UT755'
}

// Setup Links
utFooter.links = [
	{title: 'Utah.gov Home', alt: 'Utah.gov Home', href: 'https://www.utah.gov/', target: '_blank'},
	{title: 'Utah.gov Terms of Use', alt: 'Utah.gov Terms of Use', href: 'https://www.utah.gov/disclaimer.html', target: '_blank'},
	{title: 'Utah.gov Privacy Policy', alt: 'Utah.gov Privacy Policy', href: 'https://www.utah.gov/privacypolicy.html', target: '_blank'},
	{title: 'Translate Utah.gov', alt: 'Translate Utah.gov', href: 'https://www.utah.gov/translate.html', target: '_blank'}
]

utFooter.copyRightText = 'Copyright &#169; ' + new Date().getFullYear() + ' State of Utah - All rights reserved.';

// Create Nav Container
utFooter.container = utNavBuild.elementWithAttributes('div', {
		class: 'statewidefooter',
		id: 'utahgov_statewidefooter'
	}, null);

// Create Links UL
utFooter.ul = document.createElement('ul');
// Create Links in LI
for (var i = 0; i < utFooter.links.length; i++) {
	utFooter.addLi = document.createElement('li');
	utFooter.addAnchor = utNavBuild.elementWithAttributes('a', utFooter.links[i], utFooter.links[i].title);
	utFooter.addLi.appendChild(utFooter.addAnchor);
	utFooter.ul.appendChild(utFooter.addLi);
}

// utFooter.copyRightText
utFooter.copyRight = utNavBuild.elementWithAttributes('p', {class: 'utahgov_copyright'}, null);
utFooter.copyRight.innerHTML = utFooter.copyRightText;

// Check to see if Tag has a ParentNode of if parent is BODY
utFooter.hasParent = function() {
	return (utFooter.footerJS().parentNode.tagName === 'BODY');
};

// Add Nav Container to Body where Script is located,
// or first child of body
// Also Determine Class for Nav Container Based on JS file

// Check wich Element is used
utFooter.footerJS = function() {
	utFooter.container.classList.add(utFooter.class);
	return utFooter.footer;
};

// Output Container to proper location
if (utFooter.hasParent()) {
	utFooter.body.insertBefore(utFooter.container, utFooter.footerJS());
} else {
	utFooter.footerJS().parentNode.appendChild(utFooter.container);
}

// Add Elements to Nav Container
utFooter.container.appendChild(utFooter.ul);
utFooter.container.appendChild(utFooter.copyRight);